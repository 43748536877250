<template>
  <div>
    <div
      class="d-flex justify-space-around align-center"
      style="height: 70px; background-color: #e5e5e5; width: 100%; position: fixed; z-index: 999;">
      <div
        class="ml-2"
        style="max-width: 40px">
        <v-icon
          large
          @click="onBack()">mdi-chevron-left</v-icon>
      </div>
      <div
        class="ml-0 px-0">
        <img
          v-if="!forConasi"
          :style="`width: ${$vuetify.breakpoint.xs ? '165px' : '165px;'}`"
          src="../../../../assets/e_voucher_logo.svg">
        <img
          v-else
          :style="`width: ${$vuetify.breakpoint.xs ? '165px' : '165px;'}`"
          src="../../../../assets/e_voucher_logo_blue_conasi.png">
      </div>
      <div class="mr-4 text-right">
        <v-badge
          color="green"
          overlap>
          <template v-slot:badge>
            <span>{{ products }}</span>
          </template>
          <v-icon
            medium
            @click="onGoCart()">mdi-cart-outline</v-icon>
        </v-badge>
        <v-menu
          offset-y
          transition="slide-y-transition"
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-badge
              style="margin-left: 30px"
              color="green"
            >
              <v-icon
                medium
                v-on="on">mdi-dots-vertical</v-icon>
            </v-badge>
          </template>
          <v-card>
            <v-list>
              <v-list-tile @click="goToMyOrderList">
                <!-- <v-list-tile-action>
                  <v-icon color="primary">mdi-printer</v-icon>
                </v-list-tile-action> -->
                <v-list-tile-title>{{ $t('cart.myOrderTitle') }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
            <v-list v-if="isShowLoginPartnershipModal()">
              <v-list-tile @click="(partnershipEntityId && getIsActiveLinkPartnership()) ? showModalLogoutPartnership() : showModalLoginPartnership()">
                <!-- <v-list-tile-action>
                  <v-icon color="primary">mdi-calendar-multiple</v-icon>
                </v-list-tile-action> -->
                <v-list-tile-title>{{ (partnershipEntityId && getIsActiveLinkPartnership()) ? $t('partnership.logoutPartnership') : $t('partnership.loginPartnership') }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </div>
    <!--  -->
    <partnerShipModal
      ref="PartnerShipModal"
      @loginPartnership="loginPartnership"
      @logoutPartnership="logoutPartnership"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { FOR_CONASI_TARGET } from 'utils/constants'
import StoreChannelType from 'enum/storeChannelType'
import PartnerShipModal from 'Components/PartnerShipModal'
import functionUtils from 'utils/functionUtils'
import { isCookieEnabled, getCookie } from 'tiny-cookie'
export default {
  components: {
    PartnerShipModal
  },
  data: () => ({
    // eslint-disable-next-line eqeqeq
    forConasi: FOR_CONASI_TARGET,
    products: 0,
    partnershipEntityId: null,
    agentDefaultId: null,
    storeChannelType: StoreChannelType
  }),
  computed: {
    ...mapGetters([
      'GET_VOUCHER_ORDER_CART_LIST_DATA',
      'IS_CHANGE_LOGIN_OR_LOGOUT_PARTNERSHIP'
    ])
  },
  watch: {
    GET_VOUCHER_ORDER_CART_LIST_DATA () {
      let data = this.GET_VOUCHER_ORDER_CART_LIST_DATA
      this.products = this.updateNumOfItemInCart(data)
    },
    IS_CHANGE_LOGIN_OR_LOGOUT_PARTNERSHIP: function () {
      this.getVoucherOrderCart()
    }
  },
  async created () {
    this.GET_USER_DATA()
    let partnershipEntityId = sessionStorage.getItem('partnershipEntityId')
    if (partnershipEntityId) {
      this.partnershipEntityId = partnershipEntityId
    }
    await this.getVoucherOrderCart()
  },
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    updateNumOfItemInCart: function (cartList) {
      let quantity = 0
      if (cartList != null) {
        for (let i = 0, size = cartList.length; i < size; i++) {
          quantity += cartList[i].quantity
        }
      }
      return quantity
    },
    onGoCart: function () {
      this.$router.push({
        name: 'MobilePortalCart'
      })
    },
    onBack: function () {
      window.history.back()
    },
    getVoucherOrderCart: async function () {
      let filter = {
        params: {
          storeChannelId: (this.partnershipEntityId && this.getIsActiveLinkPartnership()) ? StoreChannelType.PRODUCT_PORTAL : this.storeChannelsIdInRouteParams()
        }
      }
      await this.GET_VOUCHER_ORDER_CART_LIST(filter).then(
        function () {}.bind()
      )
    },
    goToMyOrderList: function () {
      this.$router.push({
        name: 'WapPortalMyOrderList'
      })
    },
    showModalLoginPartnership: function () {
      this.$refs.PartnerShipModal.onShowModalAccessCode(StoreChannelType.MOBILE_PORTAL)
    },
    showModalLogoutPartnership: function () {
      this.$refs.PartnerShipModal.showModalConfirmLogout(StoreChannelType.MOBILE_PORTAL)
    },
    logoutPartnership: function () {
      let partnershipEntityId = sessionStorage.getItem('partnershipEntityId')
      this.partnershipEntityId = partnershipEntityId
      this.$store.state.storeChannels.isLoginPartnership = !this.$store.state.storeChannels.isLoginPartnership
    },
    loginPartnership: function () {
      let partnershipEntityId = sessionStorage.getItem('partnershipEntityId')
      this.partnershipEntityId = partnershipEntityId
      this.$store.state.storeChannels.isLoginPartnership = !this.$store.state.storeChannels.isLoginPartnership
    },
    getPartnershipEntityId: function (entityId) {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId && this.getIsActiveLinkPartnership()) {
        return partnershipEntityId
      }
      return entityId
    },
    getIsActiveLinkPartnership: function () {
      const activate = isCookieEnabled() ? getCookie('isActiveLinkPartnership') : null
      if (activate === 'true') {
        return true
      } else {
        return false
      }
    },
    isShowLoginPartnershipModal: function () {
      if (parseInt(this.storeChannelsIdInRouteParams()) === this.storeChannelType.MOBILE_PORTAL) {
        return true
      }
      return false
    },
    ...mapActions([
      'GET_VOUCHER_ORDER_CART_LIST',
      'GET_USER_DATA',
      'GET_OBJECT_SETTING'
    ])
  }
}
</script>

<style lang="scss">
.v-messages {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}
</style>

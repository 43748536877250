<template>
  <div id="core-view">
    <div
      v-if="showBar"
      style="background-color: #01baef; width: 100%;">
      <router-link
        :to="storeChannelsIdParams ? `/product-list${storeChannelsIdParams}` : `/wap-portal`"
        class="px-3 py-2 d-flex white--text font-weight-bold">
        <span>{{ $t('thirdParty.backToStore') }}</span>
      </router-link>
    </div>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </div>
</template>

<script>
import functionUtils from 'utils/functionUtils'
export default {
  metaInfo () {
    return {
      title: 'Mobile WAP Content',
      storeChannelsIdParams: ''
    }
  },
  data: () => ({
    showBar: true
  }),
  watch: {
    $route (to, from) {
      this.checkRouteChanged(to)
    }
  },
  created () {
    this.checkRouteChanged(this.$route)
  },
  methods: {
    storeChannelsIdInRouteParams: function () {
      return functionUtils.getStoreChannelsIdInRouteParams(this.$route)
    },
    checkRouteChanged: function (toRoute) {
      let storeChannelsIdParams = this.storeChannelsIdInRouteParams()
      let storeChannelsRouter = storeChannelsIdParams ? `/${storeChannelsIdParams}` : ''
      this.storeChannelsIdParams = storeChannelsRouter
      this.showBar = (this.$route.path !== '/wap-portal' + storeChannelsRouter || this.$route.path !== '/mobile-portal-home' + storeChannelsRouter)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
